
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'pagination',
  props: {
    count: {
      required: true,
      type: Number,
    },
    page: {
      required: true,
      type: Number,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  emits: ['page-change'],
  setup(props, context) {
    const pages = computed(() => {
      const array = Array(props.count - 2)
        .fill(0).map((_, i) => i + 2)
        .reduce((acc: Array<number | string>, curr) => {
          if (curr + 1 === props.page
            || curr - 1 === props.page
            || curr === props.page) {
            acc.push(curr);
          } else if (acc[acc.length - 1] !== '...') {
            acc.push('...');
          }
          return acc;
        }, []);
      return [1, ...array, props.count];
    });

    const paginationClass = (nb: number | string) => {
      if (nb === props.page) {
        return {
          'border-indigo-500': true,
          'bg-indigo-50': true,
          'dark:bg-indigo-900': true,
          'text-indigo-600': true,
          'dark:text-indigo-300': true,
          'z-10': true,
          'cursor-default': true,
          'cursor-not-allowed': props.disabled,
        };
      }
      if (nb === '...') {
        return {
          'cursor-default': true,
          'cursor-not-allowed': props.disabled,
        };
      }
      return {
        'cursor-pointer': true,
        'hover:bg-gray-50': true,
        'dark:hover:bg-gray-800': true,
        'cursor-not-allowed': props.disabled,
      };
    };

    const paginationClick = (nb: number | string) => {
      if (props.disabled) {
        return;
      }
      if (typeof nb === 'number'
        && nb > 0
        && nb < props.count + 1
        && nb !== props.page) {
        context.emit('page-change', nb);
      }
    };

    return {
      pages,
      paginationClass,
      paginationClick,
    };
  },
});
